import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";

const store = useDataStore();
const { query, mutate } = store;
//// QUERIES /////
const toast = useToast();

const service = "BOOKKEEPING";

export const getClients = async (pagination, archived = null) => {
  // getBookkeepingClients
  const payload = {
    paging: {
      limit: 10,
      page: 1,
      search: null,
      sort: null,
      filter: null,
      ...pagination,
    },
  };
  try {
    const res = await query({
      endpoint: "GetAllInvoicesClients",
      service,
      payload,
    });

    if (res.success) {
      console.log("data", res.data);
      store.$patch({
        bookkeepingClients: res.data,
      });
    }

    console.log(res);
  } catch (error) {
    throw error;
  }
};
export const GetAClient = async (id) => {
  // getBookkeepingClients
  const payload = {
    input: {
      clientId: id,
    },
  };
  try {
    const res = await query({
      endpoint: "GetAClient",
      service,
      payload,
    });

    if (res.success) {
      return res.data;
    } else {
      toast.error(res.message);

      return null;
    }
  } catch (error) {
    throw error;
  }
};
export const DOWNLOAD_CLIENT = async (payload) => {
  try {
    let res = await query({
      endpoint: "DownloadClient",
      payload: {
        ids: payload,
      },
      service,
    });
    return res;
  } catch (e) {
    toast.error(e.message);
  }
};

//// MUTATIONS /////

export const addClient = async (payload) => {
  try {
    const res = await mutate({
      endpoint: "CreateInvoicesClients",
      data: { input: payload },
      service,
    });

    if (res.success) {
      getClients();
      return res.data;
    }

    return res;
  } catch (error) {
    throw error;
  }
};

export const updateClient = async (payload) => {
  const data = {
    input: payload,
  };
  try {
    const res = await mutate({
      endpoint: "UpdateInvoiceClients",
      data,
      service,
    });

    // if (res.success) {
    //   GetAClient(payload._id);
    // }

    return res;
  } catch (error) {
    throw error;
  }
};
