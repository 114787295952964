<template>
  <div
    :style="editMode ? '' : 'box-shadow: 0px 4px 25px 0px #0000000d'"
    class="mx-auto w-full max-w-2xl pb-4 overflow-hidden flex flex-col"
    :class="editMode ? '' : 'bg-white mt-7 rounded-2xl border border-outlined'"
  >
    <div v-if="!editMode" class="border-b border-outlined p-4 mt-8">
      <h3 class="text-center text-lg md:text-2xl font-semibold">
        Client Information
      </h3>
    </div>
    <div class="w-full p-4 md:px-10 flex flex-col">
      <easiTextInput
        label="First Name"
        placeholder="First Name"
        name="firstName"
        v-model="args.firstName"
        :error="errorRules.firstName"
      />

      <easiTextInput
        label="Last Name"
        placeholder="Last Name"
        name="lastName"
        v-model="args.lastName"
        :error="errorRules.lastName"
      />

      <easiTextInput
        label="Email Address"
        placeholder="Email Address"
        name="email"
        type="email"
        required
        v-model="args.email"
        :error="errorRules.email"
      />
      <easiTextInput
        label="Phone Number"
        placeholder="Phone Number"
        name="phone"
        v-model="args.phone"
        :error="errorRules.phone"
      />
      <easiTextInput
        label="Company Name"
        placeholder="Company Name"
        name="companyName"
        v-model="args.companyName"
        :error="errorRules.companyName"
      />
      <easiTextInput
        label="Address"
        placeholder="Address"
        name="address"
        v-model="args.address"
        :error="errorRules.address"
      />

      <easiTextArea
        label="Client Note"
        placeholder="Client Note"
        name="clientNote"
        v-model="args.clientNote"
        :error="errorRules.clientNote"
      />

      <hr class="border-outlined mt-6 mb-4" />

      <div v-if="!editMode" class="grid grid-cols-2 gap-4 mt-6">
        <easiButton
          block
          variant="outlined"
          :loading="loadingNew"
          @click="handleSave(true)"
          >Save & Add New</easiButton
        >
        <easiButton :loading="loading" @click="handleSave(false)" block
          >Save</easiButton
        >
      </div>
      <div v-else class="grid grid-cols-2 gap-4 mt-6">
        <easiButton block variant="outlined" @click="$emit('cancel')"
          >Cancel</easiButton
        >
        <easiButton :loading="loading" @click="handleSave(false)" block
          >Update</easiButton
        >
      </div>
    </div>
  </div>

  <easiAlert
    v-if="isAlert"
    :caption="`Client ${editMode ? 'updated' : 'added'} successfully`"
  >
    <div class="pb-4 px-6">
      <easiButton size="medium" :loading="loading" @click="closeAlert" block
        >Okay!</easiButton
      >
    </div>
  </easiAlert>
</template>

<script setup>
import { reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { updateClient, addClient } from "@/services/book-keeping/client";
const props = defineProps({
  editMode: {
    type: Boolean,
    default: false,
  },
  data: Object,
});
const emit= defineEmits(['done'])

const store = useDataStore();
const { query, mutate } = store;

const router = useRouter();
onMounted(() => {
  //Asign values
  if (props.data) {
    console.log("data here", props.data);
    Object.keys(args).forEach((key) => {
      args[key] = props.data[key];
    });

    console.log(args);
  }
});
const categoryOptions = [
  {
    label: "Real Estate",
    value: "Real Estate",
  },
];
const sizeOptions = [
  {
    label: "Medium",
    value: "Medium",
  },
];

const isAlert = ref(false);
const loading = ref(false);
const loadingNew = ref(false);

const args = reactive({
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  companyName: "",
  address: "",
  clientNote: "",
});

const errorRules = reactive({
  firstName: false,
  lastName: false,
  email: false,
  phone: false,
  companyName: false,
  address: false,
  clientNote: false,
});

const clearFields = () => {
  Object.keys(args).map((key) => {
    args[key] = "";
  });
  clearErrors();
};
const validateForm = () => {
  clearErrors();
  if (!args.firstName) {
    errorRules.firstName = "First name is required";
    return false;
  }
  if (!args.lastName.trim()) {
    errorRules.lastName = "Last name is required";
    return false;
  } else if (!args.email) {
    errorRules.email = "Email is required";
    return false;
  } else if (!args.phone) {
    errorRules.phone = "Phone number is required";
    return false;
  }

  clearErrors();

  return true;
};
const clearErrors = () => {
  Object.keys(errorRules).map((key) => {
    errorRules[key] = false;
  });
};

const closeAlert = () => {
  clearFields();
  isAlert.value = false;
  !isAddNew.value && emit('done');
  !props.editMode && !isAddNew.value && router.go(-1)
};
const isAddNew = ref(false);
const handleSave = async (addNew = false) => {
  if (!validateForm()) return;
  isAddNew.value = addNew;
  addNew ? (loadingNew.value = true) : (loading.value = true);
  const payload = {
    address: args.address,
    lastName: args.lastName,
    firstName: args.firstName,
    phone: args.phone,
    email: args.email,

    companyName: args.companyName,
    clientNote: args.clientNote,
  };
  try {
    const res = props.editMode
      ? await updateClient({ ...payload, clientId: props.data._id })
      : await addClient({ ...payload, isSave: true });

    console.log("res is", res);
    if (res.success) {
      isAlert.value = true;

      // clearFields();
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
    loadingNew.value = false;
  }
};
</script>

<style></style>
