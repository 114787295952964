<template>
  <div class="flex flex-col gap-2 sm:gap-4 min-h-screen">
    <div class="flex items-center justify-between gap-4">
      <h3 class="font-semibold text-lg md:text-xl">All Clients</h3>
      <easiPaginAsync
        infoOnly
        leftAlign
        :totalRecords="totalRecords"
        v-model:rowsPerPage="paginationPayload.limit"
        v-model:page="paginationPayload.page"
      />
    </div>

    <easiPlainTable
      v-if="visibleData.length"
      :data="visibleData"
      :headers="tableHeaders"
      :loading="loading"
      v-model:selected="selectedData"
    >
      <template #action="slotsProp">
        <div class="flex items-center justify-center">
          <easiPopover :data="slotsProp.data" :options="dropdownOptions">
            <i class="pi pi-ellipsis-v text-dark-600" />
          </easiPopover>
        </div>
      </template>
    </easiPlainTable>
    <div v-else class="text-center">No clients to show</div>
    <easiPaginAsync
      :totalRecords="totalRecords"
      v-model:rowsPerPage="paginationPayload.limit"
      v-model:page="paginationPayload.page"
    />
  </div>

  <!-- EDIT MODAL //// -->
  <easiModal
    v-if="editModal"
    @close="editModal = false"
    :isHeader="false"
    :persistence="true"
  >
    <div class="py-12">
      <p class="text-center text-dark-800 font-medium text-2xl">Edit Client</p>
      <AddEditClient
        :editMode="true"
        :data="selectedClient"
        @cancel="editModal = false"
        @done="handleEditdone"
      />
    </div>
  </easiModal>

  <!-- //////  Delete Modal ////// -->
  <easiAlert
    :icon="opsIcon"
    v-if="deleteModal"
    :title="`<span class='text-xl'>Are you sure you want to delete <br/> this item?</span>`"
  >
    <div class="pb-4 px-6 grid grid-cols-2 gap-4">
      <easiButton
        variant="outlined"
        size="medium"
        @click="deleteModal = false"
        block
        >Cancel</easiButton
      >
      <easiButton
        size="medium"
        :loading="deleteLoading"
        @click="handleDelete"
        block
        >Yes</easiButton
      >
    </div>
  </easiAlert>
  <!-- //////  Alert Modal ////// -->
  <easiAlert v-if="isAlert" :caption="alertMessage">
    <div class="pb-4 px-6">
      <easiButton
        size="medium"
        :loading="loading"
        @click="isAlert = false"
        block
        >Okay!</easiButton
      >
    </div>
  </easiAlert>
</template>

<script setup>
import opsIcon from "@/assets/icons/empty-state.svg";
import AddEditClient from "@/components/BookKeeping/Client/AddEditClient.vue";
import { ref, computed,watch,reactive } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { getClients } from "@/services/book-keeping/client";
const props = defineProps({
  loading: Boolean,
});
const { formatAmount, formatDate } = helperFunctions;
const store = useDataStore();
const { mutate } = store;

const router = useRouter();
const storeBookKeepingClients = store.getBookkeepingClients;
const alertMessage = ref("");
const isAlert = ref(false);
const editModal = ref(false);
const totalRecords = computed(() =>
  storeBookKeepingClients && storeBookKeepingClients.paginationData
    ? storeBookKeepingClients.paginationData.totalRecords
    : 0
);
const paginationPayload = reactive({
  limit: 20,
  page: 1,
});
watch(paginationPayload, () => {
  console.log("chend", paginationPayload);
  queryclients();
});
const queryclients = async () => {
  try {
    isLoading.value;
    await getClients(paginationPayload);
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};
const deleteLoading = ref(false);
const deleteModal = ref(false);
const selectedItem = ref(null);
const toast = useToast();

const selectedData = ref([]);
const visibleData = computed(() => {
  return storeBookKeepingClients.docs || [];
});
const handleEditdone = async () => {
  await getClients();
  editModal.value = false;
};
const tableHeaders = [
  {
    style: ``,
    title: "FIRST NAME",
    field: "firstName",
  },
  {
    style: ``,
    title: "LAST NAME",
    field: "lastName",
  },
  {
    style: ``,
    title: "EMAIL ADDRESS",
    field: "email",
  },
  {
    style: ``,
    title: "CUSTOMER VALUE",
    field: "value",
    format: (val) => {
      return formatAmount(val);
    },
  },

  {
    style: ``,
    title: "DATE ADDED",
    field: "createdAt",
    type: "date",
    format: (val) => {
      return val ? formatDate(val, "DD/MM/YYYY") : "--";
    },
  },

  {
    title: "",
    field: "",
    type: "action",
  },
];
const selectedClient = ref();
const dropdownOptions = [
  {
    title: "View",
    action: (val) => {
      router.push({ name: "ViewClient", params: { id: val._id } });
    },
  },
  {
    title: "Edit",
    action: (val) => {
      selectedClient.value = val;
      editModal.value = true;
    },
  },
  {
    title: "Delete",
    action: (val) => {
      selectedClient.value = val;
      deleteModal.value = true;
    },
  },
];

const handleDelete = async () => {
  try {
    deleteLoading.value = true;

    let res = await mutate({
      endpoint: "DeleteClient",
      data: { input: { clientId: selectedClient.value._id } },
      service: "BOOKKEEPING",
    });
    if (res.success === true) {
      deleteLoading.value = false;
      deleteModal.value = false;
      alertMessage.value = "Item successfully deleted";
      isAlert.value = true;
    } else {
      toast.error(res.message);
    }
  } catch (e) {
    console.log(e);
  } finally {
    await getClients();
    deleteLoading.value = false;
  }
};
</script>

<style></style>
